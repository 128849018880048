.rms-form-submit-Btn-bg {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  background-color: #003479;
  font-size: 18px;
}

.rms-form-submit-Btn-bg-disabled {
  color: black;
}

.rms-form-submit-Btn-bg-ISLAB {
  background-color: #0082ba !important;
}


.rms-form-mandatoryFieldMissing {
  margin-bottom: 10px;
  visibility: visible;
  color: red;
}

.rms-form-mandatoryFieldResolved {
  visibility: hidden;
}