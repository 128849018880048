.confirm-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-confirm-info-wrapper {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 20px;
}

.confirm-wrapper {
	position: relative;
	z-index: 0;
}

.confirm-info-top-image {
	min-height: 280px;
	width: 100%;
	text-align: center;
	position: absolute;
	display: flex;
	flex-flow: column;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}

.confirm-content-wrapper {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin: 120px 0 50px;
	flex-flow: column;
	padding: 41px 51px;
	border: 1px solid #ced2d5;
	min-height: 600px;
	background-color: #fff;
}

.printMargin {
  padding: 30px;
}
.mobile-confirm-content-wrapper {
  display: flex;
  flex-flow: column;
  padding: 30px 20px 0;
  background-color: #fff;
}

.confirm-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 10px;
}

.confirm-top-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 95% 5%;
}

.mobile-confirm-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-top: 10px;
}

.mobile-confirm-top-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 90% 10%;
}

.confirm-major-desc {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 20px 0px 20px;
}

.mobile-confirm-major-desc {
    color: #000000;
}

.print-reservation {
  float: 'right';
  padding: '10px';
  cursor: pointer;
}

.mobile-print-reservation {
  float: 'right';
  padding: '10px';
  margin-right: 20px;
  cursor: pointer;
}

.delete-reservation-btn {
  background: red;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-family: Roboto;
  padding: 5px;
  border-radius: 5px;
}

.mobile-delete-reservation-btn {
  background-color: red;
  border: none;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 20px 0;
  font-style: 16px;
  color: #ffffff;
  float: right;
  margin-right: 5px;
  width: 140px;
}

.mobile-close-session-btn {
  background-color: #0082ba !important;
  border: none;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 20px 0;
  font-style: 16px;
  color: #ffffff;
  float: right;
  margin-right: 5px;
  width: 140px;
}


.confirm-major-items {
	display: flex;
	margin-left: 34px;
}

.confirm-header {
	display: inline-flex;
	margin: 10px 20px 10px 0px;
}

.mobile-confirm-header {
  display: flex;
  margin-top: 20px;
}

.confirm-thumbnail {
	width: 30px;
	height: 30px;
	display: flex;
}

.confirm-date-time-loc {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}

.confirm-date-time-loc p {
	display: flex;
	margin: 0;
}

.confirm-major-address {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 10px 0px 10px 34px;
}

.confirm-major-address strong {
  font-size: 20px;
  font-weight: bold;
  color: #003479;
}

.confirm-major-address p {
	line-height: 1;
}


.confirm-major-save-booking {
	display: inline;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
}

.save-booking-confirmation-text {
	min-height: 100px;
	margin: 0px 60px 0 34px;
}

.save-booking-confirmation-text p {
	margin-top: 30px;
}

.save-confirmation-btn, .save-confirmation-btn-disabled {
	align-content: flex-end;
}

.save-confirmation-btn button {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #003479;
  border-radius: 6px;
  border: transparent;
  pointer-events: initial;
}

.save-confirmation-btn-disabled button {
	height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  background-color: #999999;
  opacity: 0.5;
  border-radius: 6px;
  border: transparent;
  pointer-events: none;
}

.save-confirmation-btn button:hover {
	color: #1e6fca;
  background-color: #ffffff;
  border: 1px solid #003479;
}

.confirm-booking {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  margin-top: 10px;
  margin-left: 34px;
}

.mobile-confirm-booking {
  display: grid;
  grid-template-columns: 1fr;
}

.mobile-confirm-booking-sub {
  margin-top: 20px;
}

.save-changes-confirmation-btn button {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #003479;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
  pointer-events: initial;
}

.save-changes-confirmation-btn-disabled button {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  opacity: 0.5;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #999999;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
  pointer-events: none;
}


/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 768px) {

  .confirm-content-wrapper {
    position: absolute;
    z-index: 1;
    display: flex;
    left: 75px;
    right: 75px;
    margin: 200px 0 50px;
    flex-flow: column;
    padding: 41px 51px;
    border: 1px solid #ced2d5;
    min-height: 600px;
    background-color: #fff;
  }

  .confirm-header {
    display: inline-flex;
    margin: 20px 20px 20px 0px;
  }

	.save-booking {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: 
		"save-booking-confirmation-text"
		"save-booking-confirmation-btn"
	}

	.save-booking-confirmation-btn button {
	  border: transparent;
	  margin-bottom: 20px;
	}

}