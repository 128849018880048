#cookiePolicy {
	background-color: #fff;
}

#cookiePolicyTitle {
    float: right;
	text-decoration: underline;
	cursor: pointer;
}


.cookie-policy {

}

.cookie-policy-hidden {
	display: none;
}