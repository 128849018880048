.submit-Btn-bg-ISLAB {
    background-color: #0082ba !important;
}

.edit-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-edit-info-wrapper {
	width: 100%;
	background-color: #ffffff;
	padding-bottom: 20px;
}

.edit-info-wrapper {
	position: relative;
	z-index: 0;
}

.edit-info-top-image {
	min-height: 280px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.edit-form {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 120px;
	flex-flow: column;
}

.mobile-edit-form {
	display: flex;
	flex-flow: column;
}

.edit-o-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  width: 100%;
}

.mobile-edit-o-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  width: 100%;
}

.label-style {
	font-weight: normal;
	font-size: 18px;
	color: #000000;
}


.edit-sub-title {
  font-family: Roboto;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 20px;
  margin-top: 20px;
  grid-column: 1/3;
}

.edit-text-area {
	min-height: 50px;
	background-color: #fcfcfc;
}


.form {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.form span {
	margin-top: 10px;
}

.edit-o {
	width: 100%;
	background-color: #ffffff;
	min-height: 200px;
	padding: 41px 52px 52px;
}

.mobile-edit-o {
	width: 100%;
	background-color: #ffffff;
	padding: 30px 20px 20px;
}

.edit-submitBtn {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #003479;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.edit-submitBtn-disabled {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #999999;
	color: #000000;
	opacity: 0.5;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  pointer-events: none;
}


.edit-service-location-info {
	display: flex;
	flex-direction: column;
	margin: 0 20px 0 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;

}
.edit-service-location-info p, .mobile-edit-service-location-info p{
	display: flex;
	margin: 0;
}

.edit-service-location-info-header {
  	display: inline-flex;
	margin: 30px 20px 30px 0px;
}
.mobile-edit-service-location-info-header {
  display: flex;
  margin-top: 20px;
}

.edit-major-items {
	display: flex;
	margin-left: 34px;
}

/* On screens that are 768px or less (pc) */
@media screen and (max-width: 768px) {
	
	.edit-form {
		position: absolute;
		z-index: 1;
		display: flex;
		left: 75px;
		right: 75px;
		margin-top: 200px;
		flex-flow: column;
	}

	.edit-service-location-info-header {
		margin: 30px 20px 0px 0px;
	}

	.edit-o-title {
	  margin-bottom: 4px;
	}
}