.rms-form-field-pull-down-wrapper {
  /*background-color: red; */
}

.rms-form-field-pull-down {
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  background: white;
  border-block-color: #ebedf0;
  border-radius: 0.25rem;
  border-style: double;
}