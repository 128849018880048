a:-webkit-any-link {
  text-decoration: none;
}

.head {
	background-color: #003479 !important;
	padding: 0px !important;
	margin: 0;
}

.head-ISLAB {
    background-color: #0082ba !important;
}


.equal-width, .equal-width-accessed {
	width: 20%;
	width: calc(100% / 5);
	display: flex;
	justify-content: center;
	align-content: center;
}

.equal-width a {
	color: #fff !important;
	opacity: 0.2;
} 

.equal-width-accessed a {
	color: #fff !important;
	opacity: 0.75;
	text-decoration: none !important;
}

.equal-width a:hover {
	opacity: 1;
}

.active {
  border-bottom: 3px solid #fff !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.nav-link {
	pointer-events: none;
	color: #535A5F !important;
}

.nav-clickable {
	pointer-events: all;
	padding: 0.5rem;
}

.menu-reservation-info {
	text-align: center;
	color: #ffffff;
	font-family: 'Roboto';
	font-size: 16px;
	margin: 10px auto;
	font-weight: 400;
	text-transform: uppercase;
}

.mobile-menu-reservation-info {
	text-align: center;
	color: #ffffff;
	font-family: 'Roboto';
	font-size: 16px;
	margin: 10px auto;
	font-weight: 400;
}

.menu-reservation-info a {
	font-weight: 300;
	font-size: 14px;
	text-decoration: underline;
	text-transform: lowercase;
}

.menu-reservation-info a:hover {
	font-weight: 300;
	font-size: 14px;
	color: #ffffff;
	text-decoration: underline;
}




