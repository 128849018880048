.choose-office-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-choose-office-wrapper {
	background-color: #ffffff;
	width: 100%;
}

.office-wrapper {
	position: relative;
	z-index: 0;
}

.mobile-office-wrapper {
	padding: 30pt 20pt;
}

.choose-office-top-image {
	min-height: 280px;
	width: 100%;
	text-align: center;
	position: absolute;
	display: flex;
	flex-flow: row;
	background-size: cover;
	background-repeat: no-repeat;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.office-form {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 120px;
	flex-flow: column;
}


.office-form-DVV {
    margin-top: 120px !important;
}

.mobile-office-form {
	display: flex;
	flex-flow: column;
}

.office-upper-block {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns:1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-row: 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 30px;
	grid-template-areas:
	  "office1 office2"
}

.office-upper-block .office1 {
	grid-area: office1;
	width: 100%;
	border: 1px solid #c9c9c9;
	background-color: #ffffff;
	padding: 41px 52px 52px;
}

.office-upper-block .office2 {
	grid-area: office2;
	width: 100%;
	border: 1px solid #c9c9c9;
	background-color: #ffffff;
	padding: 41px 52px 52px;
}

.office1 .office1-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.office2 .office2-title {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;

    letter-spacing: normal;
    color: #1e6fca;
}


.mobile-office, .mobile-office-title {
	font-family: Roboto;
  font-size: 22pt;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  margin-top: 10px;
}
.mobile-office p {
	font-size: 14px;
	font-family: Roboto;
	font-style: normal;
	color: #000000;
	margin-top: 10px;
	font-weight: 300;
}

.office2 p {
	font-size: 14px;
	font-family: Roboto;
	font-style: normal;
	color: #000000;
	margin-top: 5px;
	font-weight: 300;
}
.office1 .dropdown-top, .office2 .dropdown-top {
	margin: 30px 0;
	border-radius: 3px;
}

.mobile-office .dropdown-top {
	margin: 30px 0;
}

.office2 button {
  background-color: #003479;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
}

.mobile-office-button {
  background-color: #003479;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: outset;
  padding: 10px;
  border-radius: 6px;
}

.mobile-office-button-disabled {
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: outset;
  padding: 10px;
  border-radius: 6px;
  color: black !important;
}

.mobile-office-button-ISLAB {
    background-color: #0082ba;

}
.mobile-office-lower-block-header {
	font-family: Roboto;
  font-size: 18pt;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #262626;
  margin: 40px 0 21px;
}

.office-lower-block-header {
  padding: 40px 0 21px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: -0.3px;
  color: #262626;
}

.office-lower-block {
	width: 100%;
	padding: 58px 44px 56px 52px;
	margin-bottom: 50px;
	background-color: #fff;
	border: solid 1px #ced2d5;
	display: grid;
}

.office-lower-block-DVV  {
    width: 100%;
    padding: 58px 44px 56px 52px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #fff;
    border: solid 1px #ced2d5;
    display: grid;
}

.office-lower-block-DVV p {
    font-size: 26px !important;
    color: #1e6fca;
}

.office-lower-block-header-DVV {
    font-size: 30px !important;
    color: #262626 !important;
}

.office-desc-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #000000;
}

.office-desc-text p {
    font-size: 20px;
}

.mobile-office-desc-text, .mobile-office-desc-text p {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}

.office-desc-text a {
	cursor: pointer;
	color: #1e6fca;
}

.office-desc-text .text-style-head {
  font-size: 21px;
  font-weight: bold;
  color: #1e6fca;
}

.mobile-text-style-head {
	font-size: 20px;
  	font-weight: 500;
  	display: block;
}

.office-desc-text .text-style-tail {
  color: #1e6fca;
}

.office-button {
	display: block;
	padding: 10px 15px;
	border-radius: 5px;
	color: white;
	background-color: #003479;
}

.office-button-disabled {
    color: black;
}

.office-button-ISLAB {
    background-color: #0082ba;
}

.office-button-disabled-ISLAB {
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: black;
}

.office-button-disabled-DVV {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f9f9f9 !important;
  color: black !important;
}


/* On screens that are 768px or less (tablets) */
@media screen and (max-width: 768px) {
	
	.office-upper-block {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-row-gap: 30px;
		grid-template-areas:
		  "office1" 
		  "office2"
	}

	.office-lower-block {
		width: 100%;
		padding: 58px 44px 26px 52px;
		margin-bottom: 50px;
		background-color: #fff;
		border: solid 1px #ced2d5;
		display: inline-grid;
	}
	
}

