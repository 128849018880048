.foobar {
    color: yellow;

}


.day-col {
    color: black;
    font-size: 110%;
}

.col-center-ISLAB {
    color: black;
    font-size: 110%;
}

.book-time-btn-ISLAB {
    background-color: #0082ba !important;
}

/* FONT IMPORTS */
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}


/* GENERAL */

* {
  box-sizing: border-box;
}

body {
    font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}


/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col, .colw {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
  margin-left: 0.5em;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1em 0;
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 6em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  /*background: var(--neutral-color);*/
  transition: 0.25s ease-out;
  display: flex;
  border-top: 8px solid transparent;
}

.calendar .body .weekly-cell {
  position: relative;
  height: 6em;
  /*border-right: 1px solid var(--border-color);*/
  overflow: hidden;
  pointer-events: none;
  /*background: var(--neutral-color);*/
  transition: 0.25s ease-out;
  display: flex;
  /*border-top: 8px solid transparent;*/
}

.calendar .body .col-mobile {
    flex-grow: 0;
    flex-basis: calc(100%/5);
    width: calc(100%/5);
}

.calendar .body .col-mobile-ISLAB {
    flex-grow: 0;
    flex-basis: calc(100%/5);
    width: calc(100%/5);
}

.calendar .body .col-mobile-DVV {
    flex-grow: 0;
    flex-basis: calc(100%/6);
    width: calc(100%/6);
}



.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .row .currentweek-past-days{
  border-top: 5px solid #53cbf1;
  pointer-events: none;
  background-color: var(--bg-color);
}

.calendar .row .currentweek {
  border-top: 5px solid #53cbf1;
}

.calendar .body .selected {
  border-top: 5px solid #53cbf1;
  /*border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);*/
  /*border-top: 5px solid red;*/
  border-image-slice: 1;
}


.color-selected-day-cell-button {
  background-color: #ec8336 !important;
  color: #ffffff !important;
}

/*.selected:before {
    content:"";
    position: absolute;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 50px;
    border-color: #fae0bb transparent transparent  transparent;
    z-index:9999;
}*/
.selected:after {
    content:"";
    position: absolute;
    left: 45%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #53cbf1 transparent transparent  transparent;
    z-index:9998;
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  top: .5em;
  left: .5em;

  font-family: sans-serif;
  /*font-size: 18px;*/
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
  background-color: var(--bg-color);
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col-DVV {
    flex-grow: 0;
    flex-basis: calc(100%/6) !important;
    width: calc(100%/6) !important;
}

.calendar .body .col-ISLAB {
    flex-grow: 0;
    flex-basis: calc(100%/5) !important;
    width: calc(100%/5) !important;
}


.calendar .body .colw {
  flex-grow: 0;
  flex-basis: calc(100%/6);
  width: calc(100%/6);
}

.calendar .body .colw-DVV {
    flex-grow: 0;
    flex-basis: calc(100%/7) !important;
    width: calc(100%/7) !important;;
}


.appmt-counter {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  background-color: #003479;
  color: #fff;
  border-radius: 20px;
  font-size: 16px;
  font-family: Roboto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}

.appmt-counter-ISLAB {
    background-color: #0082ba !important;
}

.appmt-counter-none {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  color: #818181;
  border-radius: 20px;
  border: 1px solid #cacaca;
  font-size: 14px;
  font-family: Roboto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}

.ghost {
  display: none;
}

.nonghost {
  display: block;
  display: initial; 
}

.month-title {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  text-transform: initial;
  margin: 0 20px;
}

.calendar-title {
  height: 39px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  text-transform: initial;
}

.weekly-current-day {
  background-color: #c7f5ff;
}

.weekly-booking-btn-booked {
 /* width: 50em;
  height: 62.7px;*/
  width: 75%;
  height: 60%;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: rgba(255, 255, 255, 0.3);
  margin: auto; 
  pointer-events: none;
  cursor: pointer;
}

.weekly-booking-btn-booked span {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #818181;
  display: flex;
  padding: 5px 0 0 5px;
  pointer-events: none;
}

/*.weekly-booking-btn:hover, .weekly-booking-btn-booked:hover {
  background-color: #c7f5ff;
  border-color: #979797;
  pointer-events: auto;
  cursor: pointer;
}*/

.weekly-booking-btn {
  background-color: #1e6fca;
  border: 1px solid #1e6fca;
  width: 75%;
  height: 60%;
  border-radius: 4px;
  margin: auto; 
  pointer-events: auto;
  cursor: pointer;
  color: #ffffff;
}

.weekly-booking-btn span {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  padding: 5px 0 0 5px;
  pointer-events: auto;
}

.weekly-daily-time {
  margin: auto 0;
  padding-left: 20px;
}

.weekly-booking-btn-unbookable {
  pointer-events: none;
  border: 1px solid #ccc;
  width: 75%;
  height: 60%;
  border-radius: 4px;
  margin: auto; 
  background-color: #eee;
  /*background: repeating-linear-gradient(
    135deg,
    #979797,
    #979797 20px,
    #ffffff 20px,
    #ffffff 40px
  );*/
}

.first {
  border-radius: 100px 0 0 100px;
  width: 40%;
  height: 36px;
}

.second {
  border-radius: 0 100px 100px 0;
  width: 40%;
  height: 36px;
}

.default {
  background: #93acc9 !important;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: 0;
}

.off {
  border: solid 1px #c6c6c6;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background: #fff !important;
  color: #000000;
}
