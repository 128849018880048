#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.choose-a-service-main {
	width: 100%;
	background-color: #f5f5f5;
}

.mobile-choose-a-service-main {
	width: 100%;
	background-color: #ffffff;
	padding: 20px;
}

.wrapper {
	z-index: 0;
	position: relative;
	top: 0px;
}

.jumbo-bg {
	min-height: 500px;
	width: 100%;
	position: absolute;
	z-index: 1;
	display: flex;
	flex-flow: row;
	justify-content: center;
	background-image: url(../images/no_frontpage_logo.png);
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}

.jumbo-bg-DVV {
	min-height: 500px;
	width: 100%;
	position: absolute;
	z-index: 1;
	display: flex;
	flex-flow: row;
	justify-content: center;
	background-image: url(../images/etusivun-perhekuva-tt.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}


.jumbo-heading {
  font-family: 'Roboto';
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.95;
  letter-spacing: -0.4px;
  color: #262626;
}

.jumbo-heading-ISLAB {
    background-color:  #0082ba;
    color: whitesmoke;
    top: -150px !important;
}


.mobile-jumbo-heading {
  font-family: 'Roboto';
  font-size: 23pt;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #1e6fca;
}

.main-desc {
	position: absolute;
	z-index: 2;
	top: 320px;
	left: 105px;
	right: 105px;
	display: flex;
	flex-flow: column;
}

.main-desc-ISLAB {
    top: 120px;
}


.mobile-main-desc {
	display: flex;
	flex-flow: column;
}

.main-description {
	background-color: #fff;
	min-height: 340px;
	border: 1px solid #ced2d5;
	text-align: center;
}

.mobile-main-description {
	background-color: #fff;
	text-align: initial;
}

.main-description img {
	margin: -2% auto 0;
}

.main-description-text {
  margin: 73px 92px 52px 93px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.mobile-main-description-text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
}

.main-description-text a {
  color: #1e6fca;
}

.service-content-header {
  margin: 40px 0 21px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: -0.3px;
  color: #262626;
}

.myleft img {
	float: left;
	width: 70px;
	height: 70px;
	display: flex;
}

.mobile-myleft img {
	float: left;
	width: 40px;
	height: 40px;
	display: flex;
}

.myleft {
	height: 70px;
	display: flex;
}

.mobile-myleft {
	display: flex;
	margin-right: 0.5rem!important;
}

.myright {
	font-family: Roboto;
	flex-flow: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.mobile-myright {
	font-family: Roboto;
	flex-flow: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.double {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 3em;
}

.mobile-double {
	display: grid;
	margin-top: 20px;
}

.myright-title {
	height: 70px;
	width: 100%;
	display: inline-flex;
	align-items: center;
	word-wrap: break-word;
}

.mobile-myright-title {
	display: flex;
}

.link-style {
	width: 100%;
	padding: 7px 0;
}

.mobile-link-style {
	width: 100%;
	padding: 5px 0;
}

.link-style a {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1e6fca;
}

.mobile-link-style a {
	font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.media {
	margin-bottom: 10px;
}

/* On screens that are 1824px or less (large screens) */
@media screen and (max-width: 1824px) {

	.jumbo-heading {
	  position: relative;
	  top: -170px;
	  margin-top: -60px;
	  text-align: center;
	  font-size: 40px;
	}


	.main-description {
		top: 320px;
		left: 90px;
		right: 90px;
		text-align: center;
	}

	.main-description img {
		margin: -2.5% auto 0;
	}
}

/* On screens that are 1824px or less (large screens) */
@media screen and (max-width: 1524px) {
	.main-description img {
		margin: -3% auto 0;
	}
}


/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 3000px) {
	.jumbo-heading {
	  position: relative;
	  margin-top: -75px;
	  text-align: center;
	  font-size: 40px;
      top: 30px;
	}

	.main-description {
		top: 320px;
		left: 50px;
		right: 50px;
		text-align: center;
	}

	.main-description-text {
		margin: 50px;
	}

	.main-description img {
		margin: -4.5% auto 0;
	}
}

/* On screens that are 768px or less (tablets) */
@media screen and (max-width: 768px) {
	.double {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 3em;
	}

	.myleft img {
		width: 50px;
		height:0px;
	}
}