.review-service-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-review-service-wrapper {
	width: 100%;
	background-color: #ffffff;
	padding-bottom: 20px;
}

.review-wrapper {
	position: relative;
	z-index: 0;
}

.review-service-bg-image {
	min-height: 280px;
	min-width: 100%;
	background-image: url('../images/palaute-taustakuva.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.review-block {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 200px;
	flex-flow: column;
}

.mobile-review-block {
	display: flex;
	flex-flow: column;
}

.review-icons {
	background-color: #fff;
	min-height: 340px;
	border: 1px solid #ced2d5;
	text-align: center;
}

.mobile-review-icons {
	background-color: #fff;
	text-align: center;
	margin: 20px;
}

.review-icons-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  margin: 40px auto;
}

.mobile-review-icons-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  text-align: left;
  margin-bottom: 20px;
}

.review-icons-grid {
	display: grid;
	margin: 0 auto;
	min-height: 100px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 
	"icon1 icon2 icon3 icon4 icon5"
}

.review-icons-grid .icon1 {
	grid-area: icon1;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon2 {
	grid-area: icon2;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon3 {
	grid-area: icon3;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon4 {
	grid-area: icon4;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon5 {
	grid-area: icon5;
	display: flex;
	flex-direction: column;
}

.icon1, .icon2, .icon3, .icon4, .icon5, img {
	display: flex;
	align-self: center;
}

.label-style {
	font-weight: 600;
}

.mobile-label-style {
	font-weight: 600;
	margin-top: 10px;
}

.naama-1-iso {
  width: 90px;
  height: 90px;
  object-fit: contain;
}

.mobile-naama-1-iso {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.loistavasti {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mobile-loistavasti {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.review-icons-footer {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 50px auto 30px;
}

.mobile-review-icons-footer {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-top: 20px;
}

.review-icons-footer .text-style {
  color: #1e6fca;
}

/* Lower block */
.review-lower-block {
	margin: 39px 0 50px;
	border: solid 1px #ced2d5;
	background-color: #ffffff;
}

.mobile-review-lower-block {
	/*margin: 39px 0 50px;*/
	/*border: solid 1px #ced2d5;*/
}

.review-lower-block-divisions {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 20px;
	grid-template-areas:
	  "review-lower-left review-lower-right"
}

.mobile-review-lower-block-divisions {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
	  "review-lower-left"
}

.review-lower-block-title {
	margin: 52px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.mobile-review-lower-block-title {
  margin: 20px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.review-lower-left {
	grid-area: review-lower-left;
	width: 100%;
	padding: 0 52px;
}

.mobile-review-lower-left {
	grid-area: review-lower-left;
	width: 100%;
	padding: 0 20px;
}

.review-lower-right {
	grid-area: review-lower-right;
	width: 100%;
	padding: 0 52px;
}

.review-feedback-button {
  border-radius: 6px;
  background: #1e6fca;
  border: 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 20px;
}

.review-go-back-button {
  border-radius: 6px;
  background: #1e6fca;
  border: 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 20px;
  margin-left: 20px
}

.review-text-area {
	min-height: 100px;
	background-color: #f4f4f4;
}

.feedback-title, .reviewer-email-address {
	background-color: #f4f4f4;
}


/* On screens that are 768px or less (pc) */
@media screen and (max-width: 768px) {

	.review-block {
		position: absolute;
		z-index: 1;
		display: flex;
		left: 75px;
		right: 75px;
		margin-top: 200px;
		flex-flow: column;
	}

	.review-lower-block-divisions {
		grid-column-gap: 10px;
	}

	.review-lower-left, .review-lower-right {
		padding: 0 25px;
	}

}