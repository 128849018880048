.topDiv {
  padding: "0 4em"; 
  background-color: '#fff';
}

.mobileTopDiv {
    margin: 0.75em;
}

.signInButton {
	border-radius: 3px;
	border: solid 1px #535A5F;
	background-color: #fff;
	display: flex;
	height: 40px;
    pointer-events: auto !important;
    cursor: pointer;
    margin-left: 1em;
}

.mobileSignInButton {
  border-radius: 3px;
  border: solid 1px #ccc;
  background-color: #fff;
  display: flex;
  height: 40px;
  pointer-events: auto !important;
  cursor: pointer;
}

.signInButton img {
	display: flex;
	float: left;
	margin: 0 10px;
}

.signInText {
  	font-size: 16px;
  	height: 20px;
  	font-family: Roboto;
  	font-weight: 300;
  	font-style: normal;
  	font-stretch: normal;
    color: #535A5F;
  	display: inline-flex;
  	vertical-align: top;
  	line-height: 1.25;
    letter-spacing: -0.2px;
    margin-right: 10px;
}

.mobileSignInText {
    font-size: 16px;
    font-family: Roboto;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    color: #535A5F;
    text-align: center;
    display: block;
    vertical-align: middle;
    line-height: 1.25;
    letter-spacing: -0.2px;
    margin-right: 10px;
}

.mobileDropdown .nav-link {
    padding-top: 0.375em;
    padding-right: 0.375em;
    padding-left: 0.375em;
}

.nav-button {
    color: #535A5F !important;
}

.signInTextReviewService {
	font-size: 16px;
  	height: 20px;
  	font-family: Roboto;
  	font-weight: 300;
  	font-style: normal;
  	font-stretch: normal;
    color: #535A5F;
  	display: block;
    text-align: center;
  	vertical-align: middle;
  	line-height: 1.25;
    letter-spacing: -0.2px;
    margin: auto;
    font-weight: 500;
}

.versionStatus {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  font-size: 10px;
  padding-bottom: 12px;
}

.do-align-right-ISLAB {
  position: absolute;
  right: 40px;
}

.versionStatus p {
  font-style: bold;
  font-size: 12px;
  color:#000;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}

.langDropdown {
	border: 1px solid #535A5F;
	border-radius: 3px;
	background-color: #fff;
	margin-left: 1em;
	height: 40px;
    pointer-events: auto !important;
    color: #535A5F;
}

.mobileLangDropdown {
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  height: 40px;
  pointer-events: auto !important;
  cursor: pointer;
  text-align: center;
}


.navbar {
	background-color: #fff;
}

.modal-heading {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 2em auto;
}

.modal-description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 0 2em;
  margin-bottom: 2em;
}

.modal-input {
  margin-left: 1.5em;
  margin-right: 1.5em;
  overflow: hidden;
}

.bttns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin: 0 2em;
  margin-bottom: 2em;
}

.review-button-ISLAB {
	display: none !important;
}

/* MOBILE */

.navbar-toggle-menu-name {
  padding-right: 10px;
  text-decoration: none;
  font-style: normal;
}

.navbar-nav {
  display: flex;
}

.navbar-collapse {
	justify-content: flex-end;
}