@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  background-color: #ffcc00;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:-webkit-any-link {
  text-decoration: none;
}

.head {
	background-color: #003479 !important;
	padding: 0px !important;
	margin: 0;
}

.head-ISLAB {
    background-color: #0082ba !important;
}


.equal-width, .equal-width-accessed {
	width: 20%;
	width: calc(100% / 5);
	display: flex;
	justify-content: center;
	align-content: center;
}

.equal-width a {
	color: #fff !important;
	opacity: 0.2;
} 

.equal-width-accessed a {
	color: #fff !important;
	opacity: 0.75;
	text-decoration: none !important;
}

.equal-width a:hover {
	opacity: 1;
}

.active {
  border-bottom: 3px solid #fff !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.nav-link {
	pointer-events: none;
	color: #535A5F !important;
}

.nav-clickable {
	pointer-events: all;
	padding: 0.5rem;
}

.menu-reservation-info {
	text-align: center;
	color: #ffffff;
	font-family: 'Roboto';
	font-size: 16px;
	margin: 10px auto;
	font-weight: 400;
	text-transform: uppercase;
}

.mobile-menu-reservation-info {
	text-align: center;
	color: #ffffff;
	font-family: 'Roboto';
	font-size: 16px;
	margin: 10px auto;
	font-weight: 400;
}

.menu-reservation-info a {
	font-weight: 300;
	font-size: 14px;
	text-decoration: underline;
	text-transform: lowercase;
}

.menu-reservation-info a:hover {
	font-weight: 300;
	font-size: 14px;
	color: #ffffff;
	text-decoration: underline;
}





#cookiePolicy {
	background-color: #fff;
}

#cookiePolicyTitle {
    float: right;
	text-decoration: underline;
	cursor: pointer;
}


.cookie-policy {

}

.cookie-policy-hidden {
	display: none;
}
.topDiv {
  padding: "0 4em"; 
  background-color: '#fff';
}

.mobileTopDiv {
    margin: 0.75em;
}

.signInButton {
	border-radius: 3px;
	border: solid 1px #535A5F;
	background-color: #fff;
	display: flex;
	height: 40px;
    pointer-events: auto !important;
    cursor: pointer;
    margin-left: 1em;
}

.mobileSignInButton {
  border-radius: 3px;
  border: solid 1px #ccc;
  background-color: #fff;
  display: flex;
  height: 40px;
  pointer-events: auto !important;
  cursor: pointer;
}

.signInButton img {
	display: flex;
	float: left;
	margin: 0 10px;
}

.signInText {
  	font-size: 16px;
  	height: 20px;
  	font-family: Roboto;
  	font-weight: 300;
  	font-style: normal;
  	font-stretch: normal;
    color: #535A5F;
  	display: inline-flex;
  	vertical-align: top;
  	line-height: 1.25;
    letter-spacing: -0.2px;
    margin-right: 10px;
}

.mobileSignInText {
    font-size: 16px;
    font-family: Roboto;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    color: #535A5F;
    text-align: center;
    display: block;
    vertical-align: middle;
    line-height: 1.25;
    letter-spacing: -0.2px;
    margin-right: 10px;
}

.mobileDropdown .nav-link {
    padding-top: 0.375em;
    padding-right: 0.375em;
    padding-left: 0.375em;
}

.nav-button {
    color: #535A5F !important;
}

.signInTextReviewService {
	font-size: 16px;
  	height: 20px;
  	font-family: Roboto;
  	font-weight: 300;
  	font-style: normal;
  	font-stretch: normal;
    color: #535A5F;
  	display: block;
    text-align: center;
  	vertical-align: middle;
  	line-height: 1.25;
    letter-spacing: -0.2px;
    margin: auto;
    font-weight: 500;
}

.versionStatus {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  font-size: 10px;
  padding-bottom: 12px;
}

.do-align-right-ISLAB {
  position: absolute;
  right: 40px;
}

.versionStatus p {
  font-style: bold;
  font-size: 12px;
  color:#000;
  opacity: 0.8;
  margin: 0;
  padding: 0;
}

.langDropdown {
	border: 1px solid #535A5F;
	border-radius: 3px;
	background-color: #fff;
	margin-left: 1em;
	height: 40px;
    pointer-events: auto !important;
    color: #535A5F;
}

.mobileLangDropdown {
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  height: 40px;
  pointer-events: auto !important;
  cursor: pointer;
  text-align: center;
}


.navbar {
	background-color: #fff;
}

.modal-heading {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 2em auto;
}

.modal-description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 0 2em;
  margin-bottom: 2em;
}

.modal-input {
  margin-left: 1.5em;
  margin-right: 1.5em;
  overflow: hidden;
}

.bttns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin: 0 2em;
  margin-bottom: 2em;
}

.review-button-ISLAB {
	display: none !important;
}

/* MOBILE */

.navbar-toggle-menu-name {
  padding-right: 10px;
  text-decoration: none;
  font-style: normal;
}

.navbar-nav {
  display: flex;
}

.navbar-collapse {
	justify-content: flex-end;
}
#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.choose-a-service-main {
	width: 100%;
	background-color: #f5f5f5;
}

.mobile-choose-a-service-main {
	width: 100%;
	background-color: #ffffff;
	padding: 20px;
}

.wrapper {
	z-index: 0;
	position: relative;
	top: 0px;
}

.jumbo-bg {
	min-height: 500px;
	width: 100%;
	position: absolute;
	z-index: 1;
	display: flex;
	flex-flow: row;
	justify-content: center;
	background-image: url(/frontend/static/media/no_frontpage_logo.114ae630.png);
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}

.jumbo-bg-DVV {
	min-height: 500px;
	width: 100%;
	position: absolute;
	z-index: 1;
	display: flex;
	flex-flow: row;
	justify-content: center;
	background-image: url(/frontend/static/media/etusivun-perhekuva-tt.662ab471.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}


.jumbo-heading {
  font-family: 'Roboto';
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.95;
  letter-spacing: -0.4px;
  color: #262626;
}

.jumbo-heading-ISLAB {
    background-color:  #0082ba;
    color: whitesmoke;
    top: -150px !important;
}


.mobile-jumbo-heading {
  font-family: 'Roboto';
  font-size: 23pt;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #1e6fca;
}

.main-desc {
	position: absolute;
	z-index: 2;
	top: 320px;
	left: 105px;
	right: 105px;
	display: flex;
	flex-flow: column;
}

.main-desc-ISLAB {
    top: 120px;
}


.mobile-main-desc {
	display: flex;
	flex-flow: column;
}

.main-description {
	background-color: #fff;
	min-height: 340px;
	border: 1px solid #ced2d5;
	text-align: center;
}

.mobile-main-description {
	background-color: #fff;
	text-align: initial;
}

.main-description img {
	margin: -2% auto 0;
}

.main-description-text {
  margin: 73px 92px 52px 93px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.mobile-main-description-text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
}

.main-description-text a {
  color: #1e6fca;
}

.service-content-header {
  margin: 40px 0 21px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: -0.3px;
  color: #262626;
}

.myleft img {
	float: left;
	width: 70px;
	height: 70px;
	display: flex;
}

.mobile-myleft img {
	float: left;
	width: 40px;
	height: 40px;
	display: flex;
}

.myleft {
	height: 70px;
	display: flex;
}

.mobile-myleft {
	display: flex;
	margin-right: 0.5rem!important;
}

.myright {
	font-family: Roboto;
	flex-flow: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.mobile-myright {
	font-family: Roboto;
	flex-flow: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.double {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 3em;
}

.mobile-double {
	display: grid;
	margin-top: 20px;
}

.myright-title {
	height: 70px;
	width: 100%;
	display: inline-flex;
	align-items: center;
	word-wrap: break-word;
}

.mobile-myright-title {
	display: flex;
}

.link-style {
	width: 100%;
	padding: 7px 0;
}

.mobile-link-style {
	width: 100%;
	padding: 5px 0;
}

.link-style a {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1e6fca;
}

.mobile-link-style a {
	font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.media {
	margin-bottom: 10px;
}

/* On screens that are 1824px or less (large screens) */
@media screen and (max-width: 1824px) {

	.jumbo-heading {
	  position: relative;
	  top: -170px;
	  margin-top: -60px;
	  text-align: center;
	  font-size: 40px;
	}


	.main-description {
		top: 320px;
		left: 90px;
		right: 90px;
		text-align: center;
	}

	.main-description img {
		margin: -2.5% auto 0;
	}
}

/* On screens that are 1824px or less (large screens) */
@media screen and (max-width: 1524px) {
	.main-description img {
		margin: -3% auto 0;
	}
}


/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 3000px) {
	.jumbo-heading {
	  position: relative;
	  margin-top: -75px;
	  text-align: center;
	  font-size: 40px;
      top: 30px;
	}

	.main-description {
		top: 320px;
		left: 50px;
		right: 50px;
		text-align: center;
	}

	.main-description-text {
		margin: 50px;
	}

	.main-description img {
		margin: -4.5% auto 0;
	}
}

/* On screens that are 768px or less (tablets) */
@media screen and (max-width: 768px) {
	.double {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 3em;
	}

	.myleft img {
		width: 50px;
		height:0px;
	}
}
.choose-office-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-choose-office-wrapper {
	background-color: #ffffff;
	width: 100%;
}

.office-wrapper {
	position: relative;
	z-index: 0;
}

.mobile-office-wrapper {
	padding: 30pt 20pt;
}

.choose-office-top-image {
	min-height: 280px;
	width: 100%;
	text-align: center;
	position: absolute;
	display: flex;
	flex-flow: row;
	background-size: cover;
	background-repeat: no-repeat;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.office-form {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 120px;
	flex-flow: column;
}


.office-form-DVV {
    margin-top: 120px !important;
}

.mobile-office-form {
	display: flex;
	flex-flow: column;
}

.office-upper-block {
	display: grid;
	grid-template-columns: 1fr 1fr;
	-ms-grid-row: 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 30px;
	grid-template-areas:
	  "office1 office2"
}

.office-upper-block .office1 {
	grid-area: office1;
	width: 100%;
	border: 1px solid #c9c9c9;
	background-color: #ffffff;
	padding: 41px 52px 52px;
}

.office-upper-block .office2 {
	grid-area: office2;
	width: 100%;
	border: 1px solid #c9c9c9;
	background-color: #ffffff;
	padding: 41px 52px 52px;
}

.office1 .office1-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.office2 .office2-title {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;

    letter-spacing: normal;
    color: #1e6fca;
}


.mobile-office, .mobile-office-title {
	font-family: Roboto;
  font-size: 22pt;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  margin-top: 10px;
}
.mobile-office p {
	font-size: 14px;
	font-family: Roboto;
	font-style: normal;
	color: #000000;
	margin-top: 10px;
	font-weight: 300;
}

.office2 p {
	font-size: 14px;
	font-family: Roboto;
	font-style: normal;
	color: #000000;
	margin-top: 5px;
	font-weight: 300;
}
.office1 .dropdown-top, .office2 .dropdown-top {
	margin: 30px 0;
	border-radius: 3px;
}

.mobile-office .dropdown-top {
	margin: 30px 0;
}

.office2 button {
  background-color: #003479;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
}

.mobile-office-button {
  background-color: #003479;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: outset;
  padding: 10px;
  border-radius: 6px;
}

.mobile-office-button-disabled {
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: outset;
  padding: 10px;
  border-radius: 6px;
  color: black !important;
}

.mobile-office-button-ISLAB {
    background-color: #0082ba;

}
.mobile-office-lower-block-header {
	font-family: Roboto;
  font-size: 18pt;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #262626;
  margin: 40px 0 21px;
}

.office-lower-block-header {
  padding: 40px 0 21px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: -0.3px;
  color: #262626;
}

.office-lower-block {
	width: 100%;
	padding: 58px 44px 56px 52px;
	margin-bottom: 50px;
	background-color: #fff;
	border: solid 1px #ced2d5;
	display: grid;
}

.office-lower-block-DVV  {
    width: 100%;
    padding: 58px 44px 56px 52px;
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #fff;
    border: solid 1px #ced2d5;
    display: grid;
}

.office-lower-block-DVV p {
    font-size: 26px !important;
    color: #1e6fca;
}

.office-lower-block-header-DVV {
    font-size: 30px !important;
    color: #262626 !important;
}

.office-desc-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #000000;
}

.office-desc-text p {
    font-size: 20px;
}

.mobile-office-desc-text, .mobile-office-desc-text p {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}

.office-desc-text a {
	cursor: pointer;
	color: #1e6fca;
}

.office-desc-text .text-style-head {
  font-size: 21px;
  font-weight: bold;
  color: #1e6fca;
}

.mobile-text-style-head {
	font-size: 20px;
  	font-weight: 500;
  	display: block;
}

.office-desc-text .text-style-tail {
  color: #1e6fca;
}

.office-button {
	display: block;
	padding: 10px 15px;
	border-radius: 5px;
	color: white;
	background-color: #003479;
}

.office-button-disabled {
    color: black;
}

.office-button-ISLAB {
    background-color: #0082ba;
}

.office-button-disabled-ISLAB {
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: black;
}

.office-button-disabled-DVV {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f9f9f9 !important;
  color: black !important;
}


/* On screens that are 768px or less (tablets) */
@media screen and (max-width: 768px) {
	
	.office-upper-block {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-row-gap: 30px;
		grid-template-areas:
		  "office1" 
		  "office2"
	}

	.office-lower-block {
		width: 100%;
		padding: 58px 44px 26px 52px;
		margin-bottom: 50px;
		background-color: #fff;
		border: solid 1px #ced2d5;
		display: inline-grid;
	}
	
}


#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.choose-time-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.time-wrapper {
	position: relative;
	z-index: 0;
}

.choose-time-bg-image {
	min-height: 280px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.time-block {
	position: absolute;
	z-index: 1;
	display: flex;
	flex-direction: column;
	left: 6%;
	right: 6%;
	margin-top: 120px;
	background-color: #fff;
	border: 1px solid #ced2d5;
	margin-bottom: 50px;
}

.available-times {
	min-height: 300px;
	margin: 4% 8%;
	display: flex;
	flex-direction: column;
}

.available-times-title {
  font-size: 23px;
  font-weight: bold;
  color: #003479;
  display: flex;
}

.avaialable-time-list {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	margin-top: 500px;
}

.available-time {
	display: flex;
	margin-top: 30px;
	flex-direction: row;
	align-items: center;
}

.book-time {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
}

.book-time-btn {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #003479;
  border-radius: 8px;
  border: transparent;
  margin-left: 20px;
  padding: 0 10px;
  height: 38px;
  min-width: 195px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  outline: none;
}

#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.enter-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-enter-info-wrapper {
	width: 100%;
	padding-bottom: 30px;
}

.info-wrapper {
	position: relative;
	z-index: 0;
}

.enter-info-top-image {
	min-height: 280px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.enter-form {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 120px;
	flex-flow: column;
}

.mobile-enter-form {
	display: flex;
	flex-flow: column;
}

.enter-info-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 20px;
  width: 100%;
}

.mobile-enter-info-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  width: 100%;
}

.label-style{
	font-weight: normal;
	font-size: 18px;
	color: #000000;
}

.col-md-static {
	padding: 0 !important;
}

.static-label-style {
	padding: 0 !important;
	margin: 0 !important;
	text-align: left !important;
	font-weight: 500;
	font-size: 18px;
	color: #000000;
	display: block !important;
}

.sub-title {
  font-family: Roboto;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 20px;
  margin-top: 20px;
  grid-column: 1/3;
}

.text-area {
	min-height: 50px;
}


.e-form {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.e-form span {
	margin-top: 10px;
}

.enter-info {
	width: 100%;
	background-color: #ffffff;
	min-height: 200px;
	padding: 41px 52px 52px;
}

.mobile-enter-info {
	width: 100%;
	background-color: #ffffff;
	padding: 25px 20px 20px;
}

.submitBtn {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #003479;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 30px;
}

.submit-Btn-bg-ISLAB {
    background-color: #0082ba !important;
}

.submitBtn-disabled {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #999999;
	color: #000;
	opacity: 0.5;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 30px;
  pointer-events: none
}

.mobile-submitBtn {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #003479;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding: 0 15px;
	font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
}

.mobile-submitBtn-disabled {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #999999;
	color: #000;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	opacity: 0.5;
	padding: 0 15px;
	font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
  pointer-events: none;
}

.submitBtn:hover, .mobile-submitBtn:hover {
	background: #fff;
	color: #003479;
	border: 1px solid #003479;
}

.enter-service-location-info {
	display: flex;
	flex-direction: column;
	margin: 0 20px 0 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;

}
.enter-service-location-info p, .mobile-enter-service-location-info p{
	display: flex;
	margin: 0;
}

.enter-service-location-info-ISLAB p {
    font-size: 20px;
}

.enter-service-location-info-header {
  	display: inline-flex;
	margin: 30px 20px 30px 0px;
}


.mobile-enter-service-location-info-header {
  display: flex;
  margin-top: 20px;
}

.enter-major-items {
	display: flex;
	margin-left: 34px;
}

.mandatoryFieldMissing {
	visibility: visible;
	color: red;
}
.mandatoryFieldResolved {
	visibility: hidden;
}

/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 768px) {
	.enter-form {
		display: flex;
		left: 75px;
		right: 75px;
		background-color: #000;
	}

}
.rms-form-field-pull-down-wrapper {
  /*background-color: red; */
}

.rms-form-field-pull-down {
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  background: white;
  border-block-color: #ebedf0;
  border-radius: 0.25rem;
  border-style: double;
}
.rms-form-submit-Btn-bg {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  background-color: #003479;
  font-size: 18px;
}

.rms-form-submit-Btn-bg-disabled {
  color: black;
}

.rms-form-submit-Btn-bg-ISLAB {
  background-color: #0082ba !important;
}


.rms-form-mandatoryFieldMissing {
  margin-bottom: 10px;
  visibility: visible;
  color: red;
}

.rms-form-mandatoryFieldResolved {
  visibility: hidden;
}
.submit-Btn-bg-ISLAB {
    background-color: #0082ba !important;
}

.edit-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-edit-info-wrapper {
	width: 100%;
	background-color: #ffffff;
	padding-bottom: 20px;
}

.edit-info-wrapper {
	position: relative;
	z-index: 0;
}

.edit-info-top-image {
	min-height: 280px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.edit-form {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 120px;
	flex-flow: column;
}

.mobile-edit-form {
	display: flex;
	flex-flow: column;
}

.edit-o-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  width: 100%;
}

.mobile-edit-o-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  width: 100%;
}

.label-style {
	font-weight: normal;
	font-size: 18px;
	color: #000000;
}


.edit-sub-title {
  font-family: Roboto;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 20px;
  margin-top: 20px;
  grid-column: 1/3;
}

.edit-text-area {
	min-height: 50px;
	background-color: #fcfcfc;
}


.form {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.form span {
	margin-top: 10px;
}

.edit-o {
	width: 100%;
	background-color: #ffffff;
	min-height: 200px;
	padding: 41px 52px 52px;
}

.mobile-edit-o {
	width: 100%;
	background-color: #ffffff;
	padding: 30px 20px 20px;
}

.edit-submitBtn {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #003479;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.edit-submitBtn-disabled {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #999999;
	color: #000000;
	opacity: 0.5;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  pointer-events: none;
}


.edit-service-location-info {
	display: flex;
	flex-direction: column;
	margin: 0 20px 0 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;

}
.edit-service-location-info p, .mobile-edit-service-location-info p{
	display: flex;
	margin: 0;
}

.edit-service-location-info-header {
  	display: inline-flex;
	margin: 30px 20px 30px 0px;
}
.mobile-edit-service-location-info-header {
  display: flex;
  margin-top: 20px;
}

.edit-major-items {
	display: flex;
	margin-left: 34px;
}

/* On screens that are 768px or less (pc) */
@media screen and (max-width: 768px) {
	
	.edit-form {
		position: absolute;
		z-index: 1;
		display: flex;
		left: 75px;
		right: 75px;
		margin-top: 200px;
		flex-flow: column;
	}

	.edit-service-location-info-header {
		margin: 30px 20px 0px 0px;
	}

	.edit-o-title {
	  margin-bottom: 4px;
	}
}
#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.submit-Btn-bg-ISLAB {
    background-color: #0082ba !important;
}

.check-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-check-info-wrapper {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 20px;
}

.check-wrapper {
	position: relative;
	z-index: 0;
}

.check-info-top-image {
	min-height: 280px;
	width: 100%;
	text-align: center;
	position: absolute;
	display: flex;
	flex-flow: column;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}

.check-content-wrapper {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin: 125px 0 50px;
	flex-flow: column;
	padding: 41px 51px;
	border: 1px solid #ced2d5;
	min-height: 600px;
	background-color: #fff;
}

.mobile-check-content-wrapper {
  display: flex;
  flex-flow: column;
  padding: 30px 20px 0;
  background-color: #fff;
}

.check-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
}

.mobile-check-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-top: 20px;
}

.check-major-desc {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 20px 34px 20px;

}

.check-major-items {
	display: inline-flex;
	margin-left: 34px;
}

.check-header {
	display: inline-flex;
	margin: 30px 20px 30px 0px;
}

.mobile-check-header {
  display: inline-flex;
  margin-top: 20px;
}

.check-thumbnail {
	width: 30px;
	height: 30px;
	display: flex;
}

.check-date-time-loc {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}

.check-date-time-loc p {
	display: flex;
	margin: 0;
}

.check-date-time-loc-ISLAB p {
    font-size: 20px;
}
.check-major-address {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 30px 0px 30px 34px;
}

.check-major-address strong {
  font-size: 20px;
  font-weight: bold;
  color: #003479;
}

.check-major-address p {
	line-height: 1;
}


.check-major-save-booking {
	display: inline;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
}

.save-booking {
	display: grid;
	margin-top: 20px;
	grid-template-columns: 85% 15%;
	grid-template-rows: 1fr;
	grid-template-areas: 
	"save-booking-confirmation-text save-booking-confirmation-btn"
}

.save-booking-confirmation-text {
	min-height: 100px;
	margin: 0px 60px 0 34px;
}

.save-booking-confirmation-text p {
	margin-top: 30px;
}

.save-booking-confirmation-b, .save-booking-confirmation-btn-disabled {
	min-height: 100px;
	display: grid;
}

.bk-confirm-btn {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #003479;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
  pointer-events: initial;
}

.bk-confirm-btn-disabled {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  background-color: #999999;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
}

.bk-confirm-btn-extra {
}

.bk-confirm-btn-extra-DVV {
  width: 140px
}


.tooltip {
  visibility: hidden;
  background-color: black;
  color: #ffffff;
  border-radius: 6px;
  padding: 5px;
  width: 150px;
}

.bk-confirm-btn-disabled:hover .tooltip {
  visibility: visible;
  background-color: black;
  opacity: 1;
  pointer-events: auto;
}

/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 768px) {

  .check-content-wrapper {
    position: absolute;
    z-index: 1;
    display: flex;
    left: 75px;
    right: 75px;
    margin: 200px 0 50px;
    flex-flow: column;
    padding: 41px 51px;
    border: 1px solid #ced2d5;
    min-height: 600px;
    background-color: #fff;
  }
	.save-booking {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: 
		"save-booking-confirmation-text"
		"save-booking-confirmation-btn"
	}

	.save-booking-confirmation-btn button {
	  border: transparent;
	  margin-bottom: 20px;
	}

}
.confirm-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-confirm-info-wrapper {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 20px;
}

.confirm-wrapper {
	position: relative;
	z-index: 0;
}

.confirm-info-top-image {
	min-height: 280px;
	width: 100%;
	text-align: center;
	position: absolute;
	display: flex;
	flex-flow: column;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}

.confirm-content-wrapper {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin: 120px 0 50px;
	flex-flow: column;
	padding: 41px 51px;
	border: 1px solid #ced2d5;
	min-height: 600px;
	background-color: #fff;
}

.printMargin {
  padding: 30px;
}
.mobile-confirm-content-wrapper {
  display: flex;
  flex-flow: column;
  padding: 30px 20px 0;
  background-color: #fff;
}

.confirm-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 10px;
}

.confirm-top-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 95% 5%;
}

.mobile-confirm-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-top: 10px;
}

.mobile-confirm-top-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 90% 10%;
}

.confirm-major-desc {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 20px 0px 20px;
}

.mobile-confirm-major-desc {
    color: #000000;
}

.print-reservation {
  float: 'right';
  padding: '10px';
  cursor: pointer;
}

.mobile-print-reservation {
  float: 'right';
  padding: '10px';
  margin-right: 20px;
  cursor: pointer;
}

.delete-reservation-btn {
  background: red;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-family: Roboto;
  padding: 5px;
  border-radius: 5px;
}

.mobile-delete-reservation-btn {
  background-color: red;
  border: none;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 20px 0;
  font-style: 16px;
  color: #ffffff;
  float: right;
  margin-right: 5px;
  width: 140px;
}

.mobile-close-session-btn {
  background-color: #0082ba !important;
  border: none;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin: 20px 0;
  font-style: 16px;
  color: #ffffff;
  float: right;
  margin-right: 5px;
  width: 140px;
}


.confirm-major-items {
	display: flex;
	margin-left: 34px;
}

.confirm-header {
	display: inline-flex;
	margin: 10px 20px 10px 0px;
}

.mobile-confirm-header {
  display: flex;
  margin-top: 20px;
}

.confirm-thumbnail {
	width: 30px;
	height: 30px;
	display: flex;
}

.confirm-date-time-loc {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}

.confirm-date-time-loc p {
	display: flex;
	margin: 0;
}

.confirm-major-address {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 10px 0px 10px 34px;
}

.confirm-major-address strong {
  font-size: 20px;
  font-weight: bold;
  color: #003479;
}

.confirm-major-address p {
	line-height: 1;
}


.confirm-major-save-booking {
	display: inline;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
}

.save-booking-confirmation-text {
	min-height: 100px;
	margin: 0px 60px 0 34px;
}

.save-booking-confirmation-text p {
	margin-top: 30px;
}

.save-confirmation-btn, .save-confirmation-btn-disabled {
	align-content: flex-end;
}

.save-confirmation-btn button {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #003479;
  border-radius: 6px;
  border: transparent;
  pointer-events: initial;
}

.save-confirmation-btn-disabled button {
	height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  background-color: #999999;
  opacity: 0.5;
  border-radius: 6px;
  border: transparent;
  pointer-events: none;
}

.save-confirmation-btn button:hover {
	color: #1e6fca;
  background-color: #ffffff;
  border: 1px solid #003479;
}

.confirm-booking {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  margin-top: 10px;
  margin-left: 34px;
}

.mobile-confirm-booking {
  display: grid;
  grid-template-columns: 1fr;
}

.mobile-confirm-booking-sub {
  margin-top: 20px;
}

.save-changes-confirmation-btn button {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #003479;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
  pointer-events: initial;
}

.save-changes-confirmation-btn-disabled button {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  opacity: 0.5;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #999999;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
  pointer-events: none;
}


/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 768px) {

  .confirm-content-wrapper {
    position: absolute;
    z-index: 1;
    display: flex;
    left: 75px;
    right: 75px;
    margin: 200px 0 50px;
    flex-flow: column;
    padding: 41px 51px;
    border: 1px solid #ced2d5;
    min-height: 600px;
    background-color: #fff;
  }

  .confirm-header {
    display: inline-flex;
    margin: 20px 20px 20px 0px;
  }

	.save-booking {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: 
		"save-booking-confirmation-text"
		"save-booking-confirmation-btn"
	}

	.save-booking-confirmation-btn button {
	  border: transparent;
	  margin-bottom: 20px;
	}

}
.review-service-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-review-service-wrapper {
	width: 100%;
	background-color: #ffffff;
	padding-bottom: 20px;
}

.review-wrapper {
	position: relative;
	z-index: 0;
}

.review-service-bg-image {
	min-height: 280px;
	min-width: 100%;
	background-image: url(/frontend/static/media/palaute-taustakuva.628a19ff.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.review-block {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 200px;
	flex-flow: column;
}

.mobile-review-block {
	display: flex;
	flex-flow: column;
}

.review-icons {
	background-color: #fff;
	min-height: 340px;
	border: 1px solid #ced2d5;
	text-align: center;
}

.mobile-review-icons {
	background-color: #fff;
	text-align: center;
	margin: 20px;
}

.review-icons-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  margin: 40px auto;
}

.mobile-review-icons-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  text-align: left;
  margin-bottom: 20px;
}

.review-icons-grid {
	display: grid;
	margin: 0 auto;
	min-height: 100px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 
	"icon1 icon2 icon3 icon4 icon5"
}

.review-icons-grid .icon1 {
	grid-area: icon1;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon2 {
	grid-area: icon2;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon3 {
	grid-area: icon3;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon4 {
	grid-area: icon4;
	display: flex;
	flex-direction: column;
}

.review-icons-grid .icon5 {
	grid-area: icon5;
	display: flex;
	flex-direction: column;
}

.icon1, .icon2, .icon3, .icon4, .icon5, img {
	display: flex;
	align-self: center;
}

.label-style {
	font-weight: 600;
}

.mobile-label-style {
	font-weight: 600;
	margin-top: 10px;
}

.naama-1-iso {
  width: 90px;
  height: 90px;
  object-fit: contain;
}

.mobile-naama-1-iso {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.loistavasti {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mobile-loistavasti {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.review-icons-footer {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 50px auto 30px;
}

.mobile-review-icons-footer {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-top: 20px;
}

.review-icons-footer .text-style {
  color: #1e6fca;
}

/* Lower block */
.review-lower-block {
	margin: 39px 0 50px;
	border: solid 1px #ced2d5;
	background-color: #ffffff;
}

.mobile-review-lower-block {
	/*margin: 39px 0 50px;*/
	/*border: solid 1px #ced2d5;*/
}

.review-lower-block-divisions {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 20px;
	grid-template-areas:
	  "review-lower-left review-lower-right"
}

.mobile-review-lower-block-divisions {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-template-areas:
	  "review-lower-left"
}

.review-lower-block-title {
	margin: 52px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.mobile-review-lower-block-title {
  margin: 20px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
}

.review-lower-left {
	grid-area: review-lower-left;
	width: 100%;
	padding: 0 52px;
}

.mobile-review-lower-left {
	grid-area: review-lower-left;
	width: 100%;
	padding: 0 20px;
}

.review-lower-right {
	grid-area: review-lower-right;
	width: 100%;
	padding: 0 52px;
}

.review-feedback-button {
  border-radius: 6px;
  background: #1e6fca;
  border: 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 20px;
}

.review-go-back-button {
  border-radius: 6px;
  background: #1e6fca;
  border: 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 20px;
  margin-left: 20px
}

.review-text-area {
	min-height: 100px;
	background-color: #f4f4f4;
}

.feedback-title, .reviewer-email-address {
	background-color: #f4f4f4;
}


/* On screens that are 768px or less (pc) */
@media screen and (max-width: 768px) {

	.review-block {
		position: absolute;
		z-index: 1;
		display: flex;
		left: 75px;
		right: 75px;
		margin-top: 200px;
		flex-flow: column;
	}

	.review-lower-block-divisions {
		grid-column-gap: 10px;
	}

	.review-lower-left, .review-lower-right {
		padding: 0 25px;
	}

}
.foobar {
    color: yellow;

}


.day-col {
    color: black;
    font-size: 110%;
}

.col-center-ISLAB {
    color: black;
    font-size: 110%;
}

.book-time-btn-ISLAB {
    background-color: #0082ba !important;
}

/* FONT IMPORTS */

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}


/* GENERAL */

* {
  box-sizing: border-box;
}

body {
    font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}


/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col, .colw {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
  margin-left: 0.5em;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1em 0;
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 6em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  /*background: var(--neutral-color);*/
  transition: 0.25s ease-out;
  display: flex;
  border-top: 8px solid transparent;
}

.calendar .body .weekly-cell {
  position: relative;
  height: 6em;
  /*border-right: 1px solid var(--border-color);*/
  overflow: hidden;
  pointer-events: none;
  /*background: var(--neutral-color);*/
  transition: 0.25s ease-out;
  display: flex;
  /*border-top: 8px solid transparent;*/
}

.calendar .body .col-mobile {
    flex-grow: 0;
    flex-basis: calc(100%/5);
    width: calc(100%/5);
}

.calendar .body .col-mobile-ISLAB {
    flex-grow: 0;
    flex-basis: calc(100%/5);
    width: calc(100%/5);
}

.calendar .body .col-mobile-DVV {
    flex-grow: 0;
    flex-basis: calc(100%/6);
    width: calc(100%/6);
}



.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .row .currentweek-past-days{
  border-top: 5px solid #53cbf1;
  pointer-events: none;
  background-color: var(--bg-color);
}

.calendar .row .currentweek {
  border-top: 5px solid #53cbf1;
}

.calendar .body .selected {
  border-top: 5px solid #53cbf1;
  /*border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);*/
  /*border-top: 5px solid red;*/
  border-image-slice: 1;
}


.color-selected-day-cell-button {
  background-color: #ec8336 !important;
  color: #ffffff !important;
}

/*.selected:before {
    content:"";
    position: absolute;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 50px;
    border-color: #fae0bb transparent transparent  transparent;
    z-index:9999;
}*/
.selected:after {
    content:"";
    position: absolute;
    left: 45%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #53cbf1 transparent transparent  transparent;
    z-index:9998;
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  top: .5em;
  left: .5em;

  font-family: sans-serif;
  /*font-size: 18px;*/
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
  background-color: var(--bg-color);
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col-DVV {
    flex-grow: 0;
    flex-basis: calc(100%/6) !important;
    width: calc(100%/6) !important;
}

.calendar .body .col-ISLAB {
    flex-grow: 0;
    flex-basis: calc(100%/5) !important;
    width: calc(100%/5) !important;
}


.calendar .body .colw {
  flex-grow: 0;
  flex-basis: calc(100%/6);
  width: calc(100%/6);
}

.calendar .body .colw-DVV {
    flex-grow: 0;
    flex-basis: calc(100%/7) !important;
    width: calc(100%/7) !important;;
}


.appmt-counter {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  background-color: #003479;
  color: #fff;
  border-radius: 20px;
  font-size: 16px;
  font-family: Roboto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}

.appmt-counter-ISLAB {
    background-color: #0082ba !important;
}

.appmt-counter-none {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  color: #818181;
  border-radius: 20px;
  border: 1px solid #cacaca;
  font-size: 14px;
  font-family: Roboto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}

.ghost {
  display: none;
}

.nonghost {
  display: block;
  display: initial; 
}

.month-title {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  text-transform: initial;
  margin: 0 20px;
}

.calendar-title {
  height: 39px;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e6fca;
  text-transform: initial;
}

.weekly-current-day {
  background-color: #c7f5ff;
}

.weekly-booking-btn-booked {
 /* width: 50em;
  height: 62.7px;*/
  width: 75%;
  height: 60%;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: rgba(255, 255, 255, 0.3);
  margin: auto; 
  pointer-events: none;
  cursor: pointer;
}

.weekly-booking-btn-booked span {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #818181;
  display: flex;
  padding: 5px 0 0 5px;
  pointer-events: none;
}

/*.weekly-booking-btn:hover, .weekly-booking-btn-booked:hover {
  background-color: #c7f5ff;
  border-color: #979797;
  pointer-events: auto;
  cursor: pointer;
}*/

.weekly-booking-btn {
  background-color: #1e6fca;
  border: 1px solid #1e6fca;
  width: 75%;
  height: 60%;
  border-radius: 4px;
  margin: auto; 
  pointer-events: auto;
  cursor: pointer;
  color: #ffffff;
}

.weekly-booking-btn span {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  padding: 5px 0 0 5px;
  pointer-events: auto;
}

.weekly-daily-time {
  margin: auto 0;
  padding-left: 20px;
}

.weekly-booking-btn-unbookable {
  pointer-events: none;
  border: 1px solid #ccc;
  width: 75%;
  height: 60%;
  border-radius: 4px;
  margin: auto; 
  background-color: #eee;
  /*background: repeating-linear-gradient(
    135deg,
    #979797,
    #979797 20px,
    #ffffff 20px,
    #ffffff 40px
  );*/
}

.first {
  border-radius: 100px 0 0 100px;
  width: 40%;
  height: 36px;
}

.second {
  border-radius: 0 100px 100px 0;
  width: 40%;
  height: 36px;
}

.default {
  background: #93acc9 !important;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: 0;
}

.off {
  border: solid 1px #c6c6c6;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background: #fff !important;
  color: #000000;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

