#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.enter-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-enter-info-wrapper {
	width: 100%;
	padding-bottom: 30px;
}

.info-wrapper {
	position: relative;
	z-index: 0;
}

.enter-info-top-image {
	min-height: 280px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.enter-form {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin-top: 120px;
	flex-flow: column;
}

.mobile-enter-form {
	display: flex;
	flex-flow: column;
}

.enter-info-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 20px;
  width: 100%;
}

.mobile-enter-info-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  width: 100%;
}

.label-style{
	font-weight: normal;
	font-size: 18px;
	color: #000000;
}

.col-md-static {
	padding: 0 !important;
}

.static-label-style {
	padding: 0 !important;
	margin: 0 !important;
	text-align: left !important;
	font-weight: 500;
	font-size: 18px;
	color: #000000;
	display: block !important;
}

.sub-title {
  font-family: Roboto;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #003479;
  margin-bottom: 20px;
  margin-top: 20px;
  grid-column: 1/3;
}

.text-area {
	min-height: 50px;
}


.e-form {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.e-form span {
	margin-top: 10px;
}

.enter-info {
	width: 100%;
	background-color: #ffffff;
	min-height: 200px;
	padding: 41px 52px 52px;
}

.mobile-enter-info {
	width: 100%;
	background-color: #ffffff;
	padding: 25px 20px 20px;
}

.submitBtn {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #003479;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 30px;
}

.submit-Btn-bg-ISLAB {
    background-color: #0082ba !important;
}

.submitBtn-disabled {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	background-color: #999999;
	color: #000;
	opacity: 0.5;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 30px;
  pointer-events: none
}

.mobile-submitBtn {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #003479;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	padding: 0 15px;
	font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
}

.mobile-submitBtn-disabled {
	font-family: Roboto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #999999;
	color: #000;
	border: 1px solid #fff;
	border-radius: 6px;
	height: 40px;
	opacity: 0.5;
	padding: 0 15px;
	font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
  pointer-events: none;
}

.submitBtn:hover, .mobile-submitBtn:hover {
	background: #fff;
	color: #003479;
	border: 1px solid #003479;
}

.enter-service-location-info {
	display: flex;
	flex-direction: column;
	margin: 0 20px 0 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;

}
.enter-service-location-info p, .mobile-enter-service-location-info p{
	display: flex;
	margin: 0;
}

.enter-service-location-info-ISLAB p {
    font-size: 20px;
}

.enter-service-location-info-header {
  	display: inline-flex;
	margin: 30px 20px 30px 0px;
}


.mobile-enter-service-location-info-header {
  display: flex;
  margin-top: 20px;
}

.enter-major-items {
	display: flex;
	margin-left: 34px;
}

.mandatoryFieldMissing {
	visibility: visible;
	color: red;
}
.mandatoryFieldResolved {
	visibility: hidden;
}

/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 768px) {
	.enter-form {
		display: flex;
		left: 75px;
		right: 75px;
		background-color: #000;
	}

}