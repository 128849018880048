#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.submit-Btn-bg-ISLAB {
    background-color: #0082ba !important;
}

.check-info-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.mobile-check-info-wrapper {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 20px;
}

.check-wrapper {
	position: relative;
	z-index: 0;
}

.check-info-top-image {
	min-height: 280px;
	width: 100%;
	text-align: center;
	position: absolute;
	display: flex;
	flex-flow: column;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	align-items: center;
}

.check-content-wrapper {
	position: absolute;
	z-index: 1;
	display: flex;
	left: 125px;
	right: 125px;
	margin: 125px 0 50px;
	flex-flow: column;
	padding: 41px 51px;
	border: 1px solid #ced2d5;
	min-height: 600px;
	background-color: #fff;
}

.mobile-check-content-wrapper {
  display: flex;
  flex-flow: column;
  padding: 30px 20px 0;
  background-color: #fff;
}

.check-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
}

.mobile-check-major-title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #003479;
  margin-top: 20px;
}

.check-major-desc {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 20px 34px 20px;

}

.check-major-items {
	display: inline-flex;
	margin-left: 34px;
}

.check-header {
	display: inline-flex;
	margin: 30px 20px 30px 0px;
}

.mobile-check-header {
  display: inline-flex;
  margin-top: 20px;
}

.check-thumbnail {
	width: 30px;
	height: 30px;
	display: flex;
}

.check-date-time-loc {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}

.check-date-time-loc p {
	display: flex;
	margin: 0;
}

.check-date-time-loc-ISLAB p {
    font-size: 20px;
}
.check-major-address {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
  margin: 30px 0px 30px 34px;
}

.check-major-address strong {
  font-size: 20px;
  font-weight: bold;
  color: #003479;
}

.check-major-address p {
	line-height: 1;
}


.check-major-save-booking {
	display: inline;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
}

.save-booking {
	display: grid;
	margin-top: 20px;
	grid-template-columns: 85% 15%;
	grid-template-rows: 1fr;
	grid-template-areas: 
	"save-booking-confirmation-text save-booking-confirmation-btn"
}

.save-booking-confirmation-text {
	min-height: 100px;
	margin: 0px 60px 0 34px;
}

.save-booking-confirmation-text p {
	margin-top: 30px;
}

.save-booking-confirmation-b, .save-booking-confirmation-btn-disabled {
	min-height: 100px;
	display: grid;
}

.bk-confirm-btn {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #003479;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
  pointer-events: initial;
}

.bk-confirm-btn-disabled {
  height: 40px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  background-color: #999999;
  border-radius: 6px;
  border: transparent;
  margin-bottom: 20px;
}

.bk-confirm-btn-extra {
}

.bk-confirm-btn-extra-DVV {
  width: 140px
}


.tooltip {
  visibility: hidden;
  background-color: black;
  color: #ffffff;
  border-radius: 6px;
  padding: 5px;
  width: 150px;
}

.bk-confirm-btn-disabled:hover .tooltip {
  visibility: visible;
  background-color: black;
  opacity: 1;
  pointer-events: auto;
}

/* On screens that are 1024px or less (pc) */
@media screen and (max-width: 768px) {

  .check-content-wrapper {
    position: absolute;
    z-index: 1;
    display: flex;
    left: 75px;
    right: 75px;
    margin: 200px 0 50px;
    flex-flow: column;
    padding: 41px 51px;
    border: 1px solid #ced2d5;
    min-height: 600px;
    background-color: #fff;
  }
	.save-booking {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: 
		"save-booking-confirmation-text"
		"save-booking-confirmation-btn"
	}

	.save-booking-confirmation-btn button {
	  border: transparent;
	  margin-bottom: 20px;
	}

}