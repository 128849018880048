#islab-color-ISLAB div {
    background-color: #0082ba;
}
#islab-color-ISLAB button {
    background-color: #0082ba;
}

#islab-color-ISLAB head-ISLAB {
    background-color: #0082ba !important;
}

.choose-time-wrapper {
	min-height: 100%;
	width: 100%;
	background-color: #f5f5f5;
	padding-bottom: 50px;
}

.time-wrapper {
	position: relative;
	z-index: 0;
}

.choose-time-bg-image {
	min-height: 280px;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	z-index: 1;
}

.time-block {
	position: absolute;
	z-index: 1;
	display: flex;
	flex-direction: column;
	left: 6%;
	right: 6%;
	margin-top: 120px;
	background-color: #fff;
	border: 1px solid #ced2d5;
	margin-bottom: 50px;
}

.available-times {
	min-height: 300px;
	margin: 4% 8%;
	display: flex;
	flex-direction: column;
}

.available-times-title {
  font-size: 23px;
  font-weight: bold;
  color: #003479;
  display: flex;
}

.avaialable-time-list {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	margin-top: 500px;
}

.available-time {
	display: flex;
	margin-top: 30px;
	flex-direction: row;
	align-items: center;
}

.book-time {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000000;
}

.book-time-btn {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #003479;
  border-radius: 8px;
  border: transparent;
  margin-left: 20px;
  padding: 0 10px;
  height: 38px;
  min-width: 195px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  outline: none;
}
